<template>
<div class="aa-hero-container">
    <div class="aa-hero-video">
        <video autoplay muted loop preload="auto" playsinline poster="../../../../assets/img/poster_video.jpg">
        <source src="../../../../assets/video/video.mp4" type="video/mp4">
        Your browser does not support HTML video.
        </video>
    </div>
    <div class="aa-hero-videom">
        <video autoplay muted loop preload="auto" playsinline poster="../../../../assets/img/poster_mobile.png">
        <source src="../../../../assets/video/video_movil.mp4" type="video/mp4">
        Your browser does not support HTML video.
        </video>
    </div>
</div>
</template>

<script>
export default {
    name: 'hero',
    setup(){
    }
}
</script>

<style lang="sass" scoped>
.aa-hero
    $root:&
    &-container
        height: 90%
        
    &-video
        height: 86vh
        overflow: hidden
        display: none
        position: relative
        z-index: 0
        video
            width: 100%
        @include breakpoint(md)
            height: 74vh
            display: block
        @include breakpoint(lg)
            height: 86vh

    &-videom
        display: block
        @include breakpoint(sm)
            display: block
            height: 95vh
            overflow: hidden

        @include breakpoint(md)
            display: none
        @include breakpoint(lg)
            display: none
        
        video
            width: 100%
            @include breakpoint(md)
                height: 100%
                display: block
                // width: 100%
            @include breakpoint(lg)
                width: 100%
                height: auto
                display: block


.vp-controls
    opacity: 0 !important
</style>
